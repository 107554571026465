<template>
  <div class="container d-flex flex-column justify-content-between h-75" align="center">
    <div class="row mt-3 d-flex flex-column justify-content-center align-items-center">
      <b-button
          size="lg"
          variant="primary"
          class=""
          style="width: 225px"
          @click="goToProfile"
          v-if="!isCustomer || isProxy"
      >
        HESABIM
        <b-icon class="ml-2" icon="person" aria-hidden="true"></b-icon>
      </b-button>
      <b-button
          size="lg"
          variant="primary"
          class=""
          style="width: 225px"
          @click="goToCustomerProfile"
          v-if="isCustomer && !isProxy"
      >
        HESABIM
        <b-icon class="ml-2" icon="person" aria-hidden="true"></b-icon>
      </b-button>
      <b-button
          size="lg"
          variant="primary"
          class="mt-3"
          style="width: 225px"
          @click="goToExtras"
          v-if="!isCustomer || isProxy"
      >
        DİĞER BİLGİLER
        <b-icon class="ml-2" icon="info-circle" aria-hidden="true"></b-icon>
      </b-button>
      <b-button
          size="lg"
          variant="primary"
          class="mt-3"
          style="width: 225px"
          @click="resetPassword"
      >
        ŞİFRENİ DEĞİŞTİR
        <b-icon class="ml-2" icon="arrow-counterclockwise" aria-hidden="true"></b-icon
        >
      </b-button>
      <b-button size="lg" variant="danger" class="mt-3" style="width: 225px" @click="onLogout"
      >ÇIKIŞ YAP
        <b-icon class="ml-2" icon="box-arrow-right" aria-hidden="true"></b-icon>
      </b-button>
    </div>
    <div v-if="!$vuetify.breakpoint.mobile" class="row mt-3 justify-content-center">
      <a
          href="https://apps.apple.com/tr/app/metaceviz/id1645521965"
          target="_blank"
          data-v-1940ce3b=""
          style="max-height: 60px"
      ><img
          src="/media/logos/app-store.png"
          style="max-width: 190px"
      /></a>
      <a
          class="mx-2"
          href="https://play.google.com/store/apps/details?id=com.cp.metaceviz"
          target="_blank"
          data-v-1940ce3b=""
      ><img
          src="/media/logos/google-play.png"
          style="max-width: 190px"
      /></a>
    </div>
  </div>
</template>

<script>
import {LOGOUT} from '@/core/services/store/auth.module'

export default {
  name: 'ayarlar-sayfasi',
  props: ['isProxy', 'isCustomer'],
  methods: {
    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => this.$router.push({name: 'login'}))
    },

    resetPassword() {
      document.getElementById('kt_quick_panel_close').click()
      this.$router.push({name: 'sifre-sifirla'})
    },

    goToProfile() {
      this.$router.push({name: 'profil-duzenle'})
    },

    goToCustomerProfile() {
      this.$router.push({name: 'veli-profil-duzenle'})
    },

    goToExtras() {
      this.$router.push({name: 'diger'})
    }
  }
}
</script>
