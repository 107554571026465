var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container d-flex flex-column justify-content-between h-75",
      attrs: { align: "center" },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "row mt-3 d-flex flex-column justify-content-center align-items-center",
        },
        [
          !_vm.isCustomer || _vm.isProxy
            ? _c(
                "b-button",
                {
                  staticStyle: { width: "225px" },
                  attrs: { size: "lg", variant: "primary" },
                  on: { click: _vm.goToProfile },
                },
                [
                  _vm._v(" HESABIM "),
                  _c("b-icon", {
                    staticClass: "ml-2",
                    attrs: { icon: "person", "aria-hidden": "true" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isCustomer && !_vm.isProxy
            ? _c(
                "b-button",
                {
                  staticStyle: { width: "225px" },
                  attrs: { size: "lg", variant: "primary" },
                  on: { click: _vm.goToCustomerProfile },
                },
                [
                  _vm._v(" HESABIM "),
                  _c("b-icon", {
                    staticClass: "ml-2",
                    attrs: { icon: "person", "aria-hidden": "true" },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.isCustomer || _vm.isProxy
            ? _c(
                "b-button",
                {
                  staticClass: "mt-3",
                  staticStyle: { width: "225px" },
                  attrs: { size: "lg", variant: "primary" },
                  on: { click: _vm.goToExtras },
                },
                [
                  _vm._v(" DİĞER BİLGİLER "),
                  _c("b-icon", {
                    staticClass: "ml-2",
                    attrs: { icon: "info-circle", "aria-hidden": "true" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              staticStyle: { width: "225px" },
              attrs: { size: "lg", variant: "primary" },
              on: { click: _vm.resetPassword },
            },
            [
              _vm._v(" ŞİFRENİ DEĞİŞTİR "),
              _c("b-icon", {
                staticClass: "ml-2",
                attrs: {
                  icon: "arrow-counterclockwise",
                  "aria-hidden": "true",
                },
              }),
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              staticStyle: { width: "225px" },
              attrs: { size: "lg", variant: "danger" },
              on: { click: _vm.onLogout },
            },
            [
              _vm._v("ÇIKIŞ YAP "),
              _c("b-icon", {
                staticClass: "ml-2",
                attrs: { icon: "box-arrow-right", "aria-hidden": "true" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.$vuetify.breakpoint.mobile
        ? _c("div", { staticClass: "row mt-3 justify-content-center" }, [
            _vm._m(0),
            _vm._m(1),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticStyle: { "max-height": "60px" },
        attrs: {
          href: "https://apps.apple.com/tr/app/metaceviz/id1645521965",
          target: "_blank",
          "data-v-1940ce3b": "",
        },
      },
      [
        _c("img", {
          staticStyle: { "max-width": "190px" },
          attrs: { src: "/media/logos/app-store.png" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "mx-2",
        attrs: {
          href: "https://play.google.com/store/apps/details?id=com.cp.metaceviz",
          target: "_blank",
          "data-v-1940ce3b": "",
        },
      },
      [
        _c("img", {
          staticStyle: { "max-width": "190px" },
          attrs: { src: "/media/logos/google-play.png" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }